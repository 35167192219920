<template>
    <div id="spellInfo" class="mq-row" ref="spellDetails">
        <div id="firstColumn">
            <div id="spellTitle" class="mq-row">
                <img
                    :src="selected.spell.icon[0].url"
                    :alt="selected.spell.name + ' Icon'"
                    class="titleIcon"
                />
                <div class="mq-col">
                    <h3 class="mq-np mq-nm">{{ selected.spell.name }}</h3>
                    <p class="mq-np mq-nm">{{ selected.spell.class }}</p>
                </div>
            </div>

            <spellDescription
                v-if="hasBeenMounted"
                :selected="selected"
                :singleColumn="singleColumn"
            />
        </div>

        <div id="secondColumn" class="mq-col"> 
            <video
                id="previewVideo"
                :key="selected.index"
                autoplay
                muted
                loop
                :src="vp9URL"
                :poster="require('@/assets/placeholder_16x9.png')"
            >
                <source :src="h264URL" />
            </video>

            <!-- // Damage slash, max damage for adaptive spells // -->
            <!-- //  // -->
            <div id="spellStats" class="mq-row mq-gap-half">
                <div class="mq-col mq-card" v-for="(item, name, index) in stats" :key="index">
                    <div>{{ item }}</div>
                    <div class="spellStat badge badge-light">
                        {{ formatStat(selected.spell[name]) }}
                    </div>
                </div>
            </div>

            <div v-if="selected.spell.reCasts" id="recastList" class="mq-col mq-gap mq-top-divider" >
                <h4>Recasts:</h4>
                <div
                    class="mq-card mq-col recastCard"
                    v-for="(item, index) in selected.spell.reCasts"
                    :key="index"
                >
                    <div class="mq-row mq-gap recastTitle">
                        <h5 class="mq-nm mq-np">{{ item.Action }}</h5>
                    </div>
                    <div id="recastStats">
                        <div>
                            <div class="recastStat">
                                Damage:
                                <div class="spellStat badge badge-light">
                                    {{ item.damage }}
                                </div>
                            </div>
                            <div class="recastStat">
                                Cooldown:
                                <div class="spellStat badge badge-light">
                                    {{ item.cooldown }}
                                </div>
                            </div>
                            <div class="recastStat">
                                Activation Window:
                                <div class="spellStat badge badge-light">
                                    {{ item.activationWindow }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="recastStat">
                                Wind Up:
                                <div class="spellStat badge badge-light">
                                    {{ item.windUp }}
                                </div>
                            </div>
                            <div class="recastStat">
                                Wind Down:
                                <div class="spellStat badge badge-light">
                                    {{ item.windDown }}
                                </div>
                            </div>
                            <div class="recastStat">
                                Cast Time:
                                <div class="spellStat badge badge-light">
                                    {{ item.castTime }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="descriptionMobile" />
        </div>
    </div>
</template>

<script>
import SpellDescription from '../components/SpellDescription.vue'

export default {
    name: 'Spell Details',
    props: {
        selected: Object,
        detailsSize: Number,
    },
    components: {
        SpellDescription,
    },
    data() {
        return {
            stats: {
                // property: 'Display name'
                damage: 'Damage',
                healing: 'Healing',
                cooldown: 'Cooldown',
                windUp: 'Wind Up',
                windDown: 'Wind Down',
                castTime: 'Cast Time',
                knockback: 'Knockback',
                maxRange: 'Range',
                curve: 'Curve',
            },
            containerWidth: {
                spellDetails: 0,
            },
            hasBeenMounted: false,
        }
    },
    methods: {
        formatStat(num) {
            if (isNaN(num)) {
                return num.split(', ').join(' / ')
            }
            return Math.round(num * 100) / 100
        },
    },
    computed: {
        vp9URL() {
            return this.selected.spell.previewVP9[0].url
        },
        h264URL() {
            return this.selected.spell.previewH264[0].url
        },
        singleColumn() {
            return this.detailsSize <= 0
        },
    },
    mounted() {
        this.hasBeenMounted = true
    },
    beforeUnmount() {
        this.hasBeenMounted = false
    },
}
</script>
