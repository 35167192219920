<template>
    <div class="mq-row mq-card mq-card-dark spellCard" :class="stylin">
        <div class="spellCardImage">
            <img :src="spell.icon[0].url" :alt="spell.name + ' Icon'" />
        </div>
        <div class="spellCardBody mq-col" v-if="currentWidth > 200">
            <h4 class="mq-nm">{{ spell.name }}</h4>
            <p class="mq-nm" v-if="currentWidth > 400">
                {{ spell.tagline }}
            </p>
        </div>
        <div class="spellCardTail mq-col" v-if="currentWidth > 265">
            <span class="spellClassBadge" v-if="currentWidth > 265">{{
                spell.class
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpellCard',
    props: {
        spell: Object,
        selected: Boolean,
        collapsed: Boolean,
        currentWidth: Number,
    },
    computed: {
        stylin() {
            var useThese = []
            this.selected ? useThese.push('selected') : null
            this.currentWidth < 450 ? useThese.push('condensed') : null
            return useThese.join(' ')
        },
    },
    mounted() {
        // console.log(this.spell.description)
    },
}
</script>
