<template>
    <div class="mq-card mq-col comboCard" v-if="comboInfo !== null">
        <div class="comboTitle">
            <h5 class="mq-np mq-nm">{{ comboInfo.fields.Name }}</h5>
            <div class="difficultyRating mq-row mq-gap-half">
                <div v-for="index in difficultyMax" :key="index">
                    <svg
                        class="difficultyImage"
                        :class="
                            index > comboInfo.fields.Difficulty ? 'easy' : ''
                        "
                        v-if="comboInfo.fields.Difficulty <= 3"
                        width="100%"
                        height="100%"
                        viewBox="0 0 366 403"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xml:space="preserve"
                        xmlns:serif="http://www.serif.com/"
                        style="
                            fill-rule: evenodd;
                            clip-rule: evenodd;
                            stroke-linejoin: round;
                            stroke-miterlimit: 2;
                        "
                    >
                        <path
                            d="M6.207,103.874L166.596,0.112L355.13,62.347L365.652,279.088L279.351,389.343L251.481,392.787L254.313,338.434L212.673,341.322L216.617,394.731L153.27,398.45L156.27,344.541L117.463,344.485L120.181,400.169L98.587,398.281L0.32,300.239L6.207,103.874ZM134.15,181.375L160.65,161.125L139.9,127.125L113.65,152.625L91.4,120.375L74.9,150.125L91.9,173.625L71.15,195.125L86.9,219.625L111.15,199.375L133.4,230.875L156.4,213.125L134.15,181.375ZM210.028,161L230.278,127.5L256.528,152.75L279.278,120.25L295.525,150.25L277.528,174.25L299.778,195L283.778,219.25L259.028,199.5L237.028,230.75L214.028,213L236.278,181.25L210.028,161Z"
                        />
                    </svg>
                    <svg
                        class="difficultyImage difficult"
                        v-else
                        width="100%"
                        height="100%"
                        viewBox="0 0 366 403"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xml:space="preserve"
                        xmlns:serif="http://www.serif.com/"
                        style="
                            fill-rule: evenodd;
                            clip-rule: evenodd;
                            stroke-linejoin: round;
                            stroke-miterlimit: 2;
                        "
                    >
                        <path
                            d="M6.207,103.874L166.596,0.112L355.13,62.347L365.652,279.088L279.351,389.343L251.481,392.787L254.313,338.434L212.673,341.322L216.617,394.731L153.27,398.45L156.27,344.541L117.463,344.485L120.181,400.169L98.587,398.281L0.32,300.239L6.207,103.874ZM134.15,181.375L160.65,161.125L139.9,127.125L113.65,152.625L91.4,120.375L74.9,150.125L91.9,173.625L71.15,195.125L86.9,219.625L111.15,199.375L133.4,230.875L156.4,213.125L134.15,181.375ZM210.028,161L230.278,127.5L256.528,152.75L279.278,120.25L295.525,150.25L277.528,174.25L299.778,195L283.778,219.25L259.028,199.5L237.028,230.75L214.028,213L236.278,181.25L210.028,161Z"
                        />
                    </svg>

                </div>
            </div>
        </div>
        <p class="comboTip">{{ comboInfo.fields.tip }}</p>
        <div class="comboIcons">
            <div class="mq-row" v-for="(item, index) in comboList" :key="index">
                <img
                    class="comboImg"
                    :src="item.icon[0].url"
                    :alt="item.name"
                />
                <h4
                    class="mq-nm"
                    v-if="index != Object.keys(comboList).length - 1"
                >
                    +
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
import global from '../store/global'
import { toRefs } from 'vue'

export default {
    name: 'comboCard',
    data() {
        return {
            comboInfo: null,
            comboOptions: [
                'firstSpell',
                'secondSpell',
                'thirdSpell',
                'fourthSpell',
                'fifthSpell',
                'sixthSpell',
            ],
        }
    },
    props: {
        comboID: String,
    },
    setup() {
        const { state, funcs } = global
        return { ...toRefs(state), funcs }
    },
    mounted() {
        this.comboInfo = this.funcs.getCombo(this.comboID)
    },
    computed: {
        comboList() {
            var allThese = []

            this.comboOptions.forEach((option) => {
                const optionOption = this.comboInfo.fields[option]
                if (optionOption !== undefined) {
                    allThese.push(optionOption)
                }
            })

            return allThese
        },
        difficultyMax() {
            const difficultyLevel = this.comboInfo.fields.Difficulty
            return difficultyLevel > 3 ? difficultyLevel : 3
        },
    },
}
</script>
