<template>
    <div class="appRoot">
        <div class="mq-row mainNav">
            <div class="navImage">
                
                <a href="https://magequit.com"><img :src="require('@/assets/magequit_long.png')" alt="Magequit icon" /></a>
            </div>
            <!-- <button class="btn btn-sm btn-success">Submit a Combo</button> -->
        </div>
        <filter-menu v-if="filterMenuVisible" @close="closeFilterMenu" />
        <splitpanes
            class="default-theme"
            :horizontal="display.status"
            @resized="containerSize('spellList')"
            @ready="containerSize('spellList')"
        >
            <pane
                id="listPane"
                :min-size="display.minSize"
                :size="display.size"
                :max-size="display.maxSize"
            >
                <div id="spellNav" class="mq-row">
                    <div class="mq-row" >
                        <!-- <button class="btn btn-danger btn-sm" @click="clearFilters">
                            <h4>⟲</h4>
                        </button> -->
                        <a href="#" id="resetFilterX" @click="clearFilters">
                            <span>X</span>
                        </a>
                        <button class="btn btn-primary" @click="openFilterMenu">
                            <b>Filters</b>
                        </button>
                    </div>
                    <input
                        ref="searchField"
                        type="text"
                        placeholder="Search"
                        v-model="search"
                        @keyup="funcs.handleSearch"
                    />
                    <!--
                        Maybe use material design style for search
                        Definitely use Error reporting with search for mismatches
                    -->
                </div>
                <div id="spellList" class="mq-col" ref="spellList">
                    <spellCard
                        v-for="(item, index) in filteredSpellbook"
                        :key="index"
                        :spell="filteredSpellbook[index]"
                        :selected="index == selected.index ? true : false"
                        :currentWidth="containerWidth.spellList"
                        @click="selectSpell({ index, spell: item })"
                    />
                    <div v-if="filteredSpellbook.length == 0">
                        All spells filtered. <br />
                        Please change the filters
                    </div>
                </div>
            </pane>
            <pane id="spellDetails" :min-size="50" :size="secondSize">
                <spellDetails
                    :key="selected.index"
                    :selected="selected"
                    :detailsSize="detailsSize"
                />
            </pane>
        </splitpanes>
    </div>
</template>

<script>
// Main Bootstrap css
// import 'bootstrap/dist/css/bootstrap.min.css'

// Custom CSS
import 'splitpanes/dist/splitpanes.css'
import '@/css/bootstrap.css'
import '@/css/mq-common.css'
import '@/css/mq-app.css'
import '@/css/mq-splitpanes.css'
import '@/css/mq-spellList.css'
import '@/css/mq-filterMenu.css'
import '@/css/mq-spellDetails.css'


import SpellCard from '@/components/SpellCard.vue'
import SpellDetails from '@/components/SpellDetails.vue'

const spellbook = require('@/airtable/spellbook.json').spellbook

import { Splitpanes, Pane } from 'splitpanes'
import FilterMenu from '@/components/FilterMenu.vue'

import global from '@/store/global'
import { toRefs } from 'vue'

export default {
    name: 'App',
    components: {
        SpellDetails,
        SpellCard,
        FilterMenu,
        Splitpanes,
        Pane,
    },
    setup() {
        const { state, funcs } = global
        funcs.primarySpells()
        funcs.filterAndSearch()
        return { ...toRefs(state), funcs }
    },
    data() {
        return {
            display: {
                status: true,
                minSize: 20,
                size: 30,
                maxSize: 50,
            },
            delay: 50,
            selected: { index: 0, spell: spellbook[0] },
            throttled: false,
            filterMenuVisible: false,
            windowWidth: 0,
            containerWidth: {
                spellList: 0,
            },
            search: '',
        }
    },
    methods: {
        onResize() {
            if (!this.throttled) {
                // actual callback action
                this.checkWidth()
                // we're throttled!
                this.throttled = true
                // set a timeout to un-throttle
                setTimeout(
                    function () {
                        this.throttled = false
                        this.checkWidth()
                    }.bind(this),
                    this.delay,
                )
            }
        },
        checkWidth() {
            this.containerSize('spellList')
            this.windowWidth = window.innerWidth
            if (window.innerWidth > 768) {
                this.display = {
                    status: false,
                    minSize: 9,
                    size: 30,
                    maxSize: 50,
                }
            } else {
                this.display = {
                    status: true,
                    minSize: 20,
                    size: 20,
                    maxSize: 30,
                }
            }
        },
        selectSpell(selectedSpell) {
            this.selected = selectedSpell
        },
        containerSize(target) {
            const targetSize = this.$refs[target].clientWidth
            this.containerWidth[target] = targetSize
        },
        openFilterMenu() {
            this.filterMenuVisible = true
        },
        closeFilterMenu() {
            this.filterMenuVisible = false
        },
        clearFilters() {
            this.funcs.clearAll()
            this.search = ''
        },
    },
    mounted() {
        this.onResize()
        this.containerSize('spellList')

        setTimeout(() => {
            this.containerSize('spellList')
        }, this.delay * 2)
    },
    computed: {
        secondSize() {
            return 100 - this.display.size
        },
        detailsSize() {
            // 8 is just the result of padding and stuf I think.
            return this.windowWidth - this.containerWidth.spellList - 8
        },
    },
    created() {
        window.addEventListener('resize', this.onResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>
