<template>
    <teleport to="#descriptionMobile" :disabled="!singleColumn">
        <div id="spellDescription">
            <p>{{ selected.spell.description }}</p>
            <p>{{ selected.spell.extendedDescription }}</p>
        </div>
        <div id="spellCombos" class="mq-gap mq-col  mq-top-divider" v-if="selected.spell.allCombos">
            <h4>Combos:</h4>
            <comboCard
                v-for="(item, index) in selected.spell.allCombos"
                :key="index" :comboID="item"
            />
        </div>
    </teleport>
</template>

<script>
import ComboCard from './ComboCard.vue'

export default {
    name: 'SpellDescription',
    props: {
        selected: Object,
        singleColumn: Boolean,
    },
    components: { ComboCard },
    
}
</script>
