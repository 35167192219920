import { reactive, readonly } from 'vue'
import { firstBy } from 'thenby'

const spellbook = require('@/airtable/spellbook.json').spellbook
const combos = require('@/airtable/combos.json').combos

const state = reactive({
    spellbook: spellbook,
    combos: combos,
    filteredSpellbook: spellbook,
    sortBy: 'Class',
    sortDirection: 'Ascending',
    searchText: '',
    classes: {
        primary: { enabled: true, index: 0 },
        movement: { enabled: true, index: 1 },
        melee: { enabled: true, index: 2 },
        secondary: { enabled: true, index: 3 },
        defensive: { enabled: true, index: 4 },
        utility: { enabled: true, index: 5 },
        ultimate: { enabled: true, index: 6 },
    },
    solo: {
        element: '',
        class: '',
    },
    elements: {
        fire: { enabled: true, index: 0 },
        water: { enabled: true, index: 1 },
        air: { enabled: true, index: 2 },
        earth: { enabled: true, index: 3 },
        sand: { enabled: true, index: 4 },
        nature: { enabled: true, index: 5 },
        electric: { enabled: true, index: 6 },
        steam: { enabled: true, index: 7 },
        metal: { enabled: true, index: 8 },
    },
})

var _elementsCache
var _classesCache
var _sortByCache
var _sortDirectionCache

const createObjectCopy = (source) => {
    // Using this method to quickly return a deep copy of an object
    return JSON.parse(JSON.stringify(source))
}

const funcs = {
    handleSort: (event) => {
        state.sortBy = event.target.innerText
    },
    handleDirection: (event) => {
        console.log(event)
        state.sortDirection = event.target.innerText
    },
    handleSearch: (event) => {
        state.searchText = event.target.value
        funcs.filterAndSearch()
    },
    sortIt: () => {
        const sorting =
            state.sortBy === 'Element'
                ? { first: 'elementIndex', second: 'localIndex' }
                : { first: 'localIndex', second: 'elementIndex' }

        const theSpellbook = state.filteredSpellbook

        var sortedSpellbook = theSpellbook.sort(
            firstBy(sorting.first).thenBy(sorting.second),
        )

        state.filteredSpellbook =
            state.sortDirection === 'Ascending'
                ? sortedSpellbook
                : sortedSpellbook.reverse()
    },
    searchIt: () => {
        const theSpellbook = state.filteredSpellbook
        const searchFiltered = theSpellbook.filter((spell) => {
            var doesItMatch = false
            var searchThese = [spell.name, spell.uglyName, spell.description]

            searchThese.forEach((searchMe) => {
                try {
                    if (
                        searchMe
                            .toLowerCase()
                            .indexOf(state.searchText.toLowerCase()) > -1
                    ) {
                        doesItMatch = true
                    }
                } catch (error) {
                    console.error(error)
                }
            })

            return doesItMatch
        })

        state.filteredSpellbook = searchFiltered
    },
    filterAndSearch: () => {
        var classFiltered
        var elementFiltered
        var startingSpells = state.spellbook
        classFiltered = startingSpells.filter((item) => {
            const cleanName = item.class.toLowerCase()
            return state.classes[cleanName].enabled
        })
        elementFiltered = classFiltered.filter((item) => {
            const cleanName = item.element.toLowerCase()
            return state.elements[cleanName].enabled
        })
        state.filteredSpellbook = elementFiltered

        funcs.sortIt()
        if (state.filterText !== '') {
            funcs.searchIt()
        }
    },
    toggleElement: (elementName) => {
        const cleanName = elementName.toLowerCase()
        state.elements[cleanName].enabled = !state.elements[cleanName].enabled
    },
    soloElement: (elementName) => {
        for (const element in state.elements) {
            state.elements[element].enabled = false
        }

        const cleanName = elementName.toLowerCase()
        state.elements[cleanName].enabled = true
    },
    soloClass: (className) => {
        for (const theClass in state.classes) {
            state.classes[theClass].enabled = false
        }

        const cleanName = className.toLowerCase()
        state.classes[cleanName].enabled = true
    },
    toggleClass: (className) => {
        state.classes[className].enabled = !state.classes[className].enabled
    },
    cacheFilters() {
        _elementsCache = createObjectCopy(state.elements)
        _classesCache = createObjectCopy(state.classes)
        _sortByCache = createObjectCopy(state.sortBy)
        _sortDirectionCache = createObjectCopy(state.sortDirection)
    },
    resetFilters() {
        state.elements = createObjectCopy(_elementsCache)
        state.classes = createObjectCopy(_classesCache)
        state.sortBy = createObjectCopy(_sortByCache)
        state.sortDirection = createObjectCopy(_sortDirectionCache)
    },
    clearFilter: (target) => {
        for (const element in state[target]) {
            state[target][element].enabled = true
        }
    },
    resetSearch: () => {
        state.searchText = ''
    },
    clearAll: () => {
        funcs.clearFilter('elements')
        funcs.clearFilter('classes')

        state.sortDirection = 'Ascending'
        state.sortBy = 'Class'

        funcs.resetSearch()

        funcs.filterAndSearch()
    },
    primarySpells: () => {
        var primaryFiltered
        var startingSpells = state.spellbook
        primaryFiltered = startingSpells.filter((item) => {
            const cleanName = item.class.toLowerCase()
            return cleanName === 'primary'
        })
        primaryFiltered.sort(firstBy('localIndex').thenBy('elementIndex'))

        state.primarySpells = primaryFiltered

        return primaryFiltered
    },
    getCombo: (id) => {
        return combos.find( comboItem => {
            return comboItem.id === id
        } )
    }
}

export default {
    state: readonly(state),
    funcs,
}
