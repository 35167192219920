<template>
    <div id="filterModal">
        <div id="filterMenu">
            <div id="sorting">
                <div id="sortBy">
                    Sort By:
                    <div class="dropdown">
                        <button
                            class="btn btn-primary dropdown-toggle"
                            type="button"
                            id="sortMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ sortBy }}
                        </button>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="sortMenuButton"
                        >
                            <button
                                class="dropdown-item"
                                @click="funcs.handleSort"
                            >
                                Class
                            </button>
                            <button
                                class="dropdown-item"
                                @click="funcs.handleSort"
                            >
                                Element
                            </button>
                        </div>
                    </div>
                </div>
                <div id="sortDirection">
                    Sort direction:
                    <div class="dropdown">
                        <button
                            class="btn btn-primary dropdown-toggle"
                            type="button"
                            id="sortMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ sortDirection }}
                        </button>
                        <div
                            class="dropdown-menu"
                            aria-labelledby="sortMenuButton"
                        >
                            <button
                                class="dropdown-item"
                                @click="funcs.handleDirection"
                            >
                                Ascending
                            </button>
                            <button
                                class="dropdown-item"
                                @click="funcs.handleDirection"
                            >
                                Descending
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="elementsFilter">
                <div>
                    <h3 class="filterTitle">Elements</h3>
                </div>
                <div class="filterSelectors">
                    <div
                        v-for="spell in primarySpells"
                        :key="spell.elementIndex"
                        :class="
                            elements[spell.element.toLowerCase()].enabled
                                ? null
                                : 'disabled'
                        "
                        @click.exact="funcs.toggleElement(spell.element)"
                        @click.alt="funcs.soloElement(spell.element)"
                        class="elementIcon"
                    >
                        <img
                            :src="spell.icon[0].url"
                            :alt="spell.element + ' Icon'"
                        />
                        {{ spell.element }}
                    </div>
                </div>
            </div>
            <div id="classesFilter">
                <div>
                    <h3 class="filterTitle">Classes</h3>
                </div>

                <div class="filterSelectors">
                    <button
                        v-for="(item, name) in classes"
                        class="btn smallish"
                        :class="item.enabled ? 'btn-primary' : 'btn-dark'"
                        :key="item.index"
                        @click.exact="funcs.toggleClass(name)"
                        @click.alt="funcs.soloClass(name)"
                    >
                        {{ capitalize(name) }}
                    </button>
                </div>
            </div>
            <div id="filterNav">
                <button class="btn btn-danger" @click="discardAndClose">
                    Cancel
                </button>
                <button class="btn btn-success" @click="applyAndClose">
                    Apply
                </button>
            </div>
        </div>
        <div id="filterOverlay" @click="discardAndClose"></div>
    </div>
</template>

<script>
import global from '../store/global'
import { toRefs } from 'vue'

export default {
    name: 'FilterMenu',
    setup() {
        const { state, funcs } = global
        return { ...toRefs(state), funcs }
    },
    methods: {
        discardAndClose() {
            this.funcs.resetFilters()
            this.$emit('close')
        },
        applyAndClose() {
            this.funcs.filterAndSearch()
            this.$emit('close')
        },
        capitalize(text) {
            return text.charAt(0).toUpperCase() + text.slice(1)
        },
    },
    mounted() {
        this.funcs.cacheFilters()
    },
}
</script>
